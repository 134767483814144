/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, SVGProps } from 'react';
import AllChatsIcon from '../../../../shared/icons/AllChats';
import MyChatsIcon from '../../../../shared/icons/MyChats';
import UnassignedChatsIcon from '../../../../shared/icons/UnassignedChats';
import SlaStatusIcon from '../../../../shared/icons/SlaStatus';
import { DropdownProps, DropdownVariants } from '../../../../shared/modules/dropdown';
import { FiltersState } from '../states/chat-list';
import { TextProps } from '../../../../shared/components/molecules/text-input';
import LockIcon from '../../../../shared/icons/LockIcon';
import { FEATURES } from '../../../../shared/constants/features';

export const VARIABLE_REGEX = new RegExp(/{{.+?}}/, 'g');

export const chatRowAvatarSize = 42;

export const COPY_ICON_SIZE = 16;

export const CHAT_INPUT_MIN_HEIGHT = 50;

export const MAX_NUMBER_OF_LINES_UTILITY_TEMPLATE = 3;

export const chatRowAvatarStyles = css({
  width: `${chatRowAvatarSize}px`,
  height: `${chatRowAvatarSize}px`,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '4px',
});

export const chatSettingsDropdownProps: Omit<Partial<DropdownProps>, 'value' | 'onChange'> = {
  variant: DropdownVariants.DEFAULT,
  searchable: false,
  containerProps: {
    sx: {
      height: '36px',
      maxHeight: '36px',
      width: '100%',
      minWidth: '80px',
      maxWidth: '100%',
      marginRight: '20px',
      flex: '1 1 auto',
    },
  },
  sx: {
    width: '100%',
    '.MuiInputBase-root': {
      height: '36px',
      maxHeight: '36px',
      width: '100%',
      minWidth: '80px',
      maxWidth: '100%',
      fontSize: '16px',
      fontWeight: '600',
      color: '#3D906D',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      '&:hover': {
        borderColor: '#939393',
      },
      '.MuiInputBase-formControl': {
        border: 'none !important',
        borderRadius: '0px',
        '&:hover': {
          border: 'none !important',
          borderRadius: '0px',
        },
        '.Mui-focused': {
          border: 'none !important',
          borderRadius: '0px',
        },
      },
    },
    '.Mui-focused': {
      border: '1px solid #3F906D !important',
    },
  },
  selectSx: {
    '.MuiSelect-select': {
      padding: '8px 32px 8px 12px !important',
    },
    '.MuiTypography-root': {
      fontSize: '14px !important',
      fontWeight: '500',
      width: '135px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',

      '@media (min-width: 0px) and (max-width: 1400px)': {
        width: '100%',
      },
    },
  },
  menuSx: {
    '.MuiMenu-list': {
      padding: '0px',
    },
    '.MuiButtonBase-root': {
      fontSize: '14px',
      fontWeight: '400',
      minHeight: 'auto',
      whiteSpace: 'normal',
      padding: '8px 12px',
      '&:first-of-type': {
        '&.Mui-disabled': {
          fontSize: '14px',
          fontWeight: '400',
          padding: '10px 12px 8px 12px',
          borderBottom: '1px solid #E0E0EA',
          opacity: 1,
        },
      },
    },
  },
  selectProps: {
    fullWidth: true,
  },
  searchInputProps: {
    sx: {
      overflow: 'hidden',
      '.MuiInputBase-input': {
        fontSize: '16px',
      },
    },
  },
  modalStyles: {
    zIndex: 1500,
  },
  displayEmpty: true,
};

export const CHAT_FILTER_TYPES: {
  type: FiltersState;
  label: string;
  defaultExpanded?: boolean;
  collapsible: boolean;
  hideCollapseIcon?: boolean;
  disableRenderAccordionSummary?: boolean;
  hideBorderBottom?: boolean;
  Icon?: FC<SVGProps<SVGSVGElement> & { size?: number }>;
  RightIcon?: FC<SVGProps<SVGSVGElement> & { size?: number }>;
  feature?: string;
}[] = [
  {
    type: FiltersState.ALL_CHATS,
    label: 'All chats',
    collapsible: true,
    Icon: AllChatsIcon,
  },
  {
    type: FiltersState.MY_CHATS,
    label: 'My chats',
    collapsible: true,
    Icon: MyChatsIcon,
  },
  {
    type: FiltersState.SLA_STATUS,
    label: 'SLA breached',
    Icon: SlaStatusIcon,
    collapsible: true,
    hideCollapseIcon: true,
    defaultExpanded: false,
    RightIcon: LockIcon,
    feature: FEATURES.SLA_STATUS,
  },
  {
    type: FiltersState.UNASSIGNED,
    label: 'Unassigned',
    collapsible: true,
    Icon: UnassignedChatsIcon,
  },
  {
    type: FiltersState.ASSIGNEE,
    label: 'Team member',
    collapsible: false,
    hideBorderBottom: true,
  },
  {
    type: FiltersState.TAGS,
    label: 'Tags',
    collapsible: false,
    hideBorderBottom: true,
  },
  {
    type: FiltersState.WABA,
    label: 'WABAs',
    collapsible: false,
    hideBorderBottom: true,
  },
];

export const chatFilterAccordionStyles = css`
  height: auto;
  min-height: auto;
  max-height: auto;
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  outline: none;
  box-shadow: none;
  border-radius: 0px;
  border-bottom: 1px solid #e0e0e9;

  &.Mui-expanded,
  &:first-of-type,
  &:last-of-type {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &:first-of-type {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  &:last-of-type {
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  :before {
    display: none;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root:empty {
    display: none;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root .MuiAccordionSummary-content {
    margin: 0px;
    padding: 0px;
    transition: padding 280ms ease-in-out;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root .MuiAccordionSummary-content:empty {
    display: none;
  }

  &.Mui-expanded .MuiButtonBase-root.MuiAccordionSummary-root .MuiAccordionSummary-content {
    padding: 0px;
  }

  .MuiCollapse-root .MuiAccordion-region .MuiAccordionDetails-root {
    padding: 0px;
  }

  .MuiCollapse-root .MuiAccordion-region .MuiAccordionDetails-root:empty {
    display: none;
  }
`;

export const tagsSearchInputProps: Partial<TextProps> = {
  sx: {
    height: '40px',
    maxWidth: '100%',
    flex: '1 1 auto',
    overflow: 'hidden',
    '.MuiFormControl-root': {
      margin: '0px',
    },
    '.MuiInputBase-root::before': {
      borderBottom: 'none !important',
    },
    '.MuiInputBase-root,.MuiInput-root': {
      '&::before': {
        border: 'none',
      },
      '&::after': {
        border: 'none',
      },
      '&:hover:not(.Mui-disabled)::before': {
        border: 'none',
      },
      'input::placeholder, textArea::placeholder': {
        color: 'inherit',
        opacity: '1',
      },
    },
  },
  autoFocus: true,
  textInputProps: {
    type: 'search',
    sx: {
      height: '40px',
      minWidth: 'min-content',
      maxWidth: '100%',
      background: '#FFFFFF',
      borderRadius: '8px',
      justifyContent: 'center',
      padding: '0px 8px',
      margin: '0px',
      border: '1px solid #E0E0EA',
      '&:hover': {
        borderColor: '#2D2D2D',
      },
      '&:focus': {
        borderColor: '#3F906D',
      },
    },
    inputProps: {
      sx: {
        height: '100%',
        color: 'inherit',
        fontSize: '14px',
        fontWeight: '400',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&.Mui-focused': {
          borderColor: '#3F906D',
        },
        '&::placeholder': {
          color: 'inherit',
          opacity: '1',
        },
        '&::-webkit-search-cancel-button': {
          display: 'none',
        },
      },
    },
    className: 'nofocus',
    autoFocus: true,
    autoComplete: 'off',
    onKeyDown: (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    },
  },
};

export const memberAccessManagementContainerStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '500px',
  maxWidth: '100%',
  background: '#fff',
  boxShadow: 24,
  overflow: 'hidden',
  borderRadius: '4px',
  minHeight: '500px',
  height: '100%',
};

export const SendImageMediaType = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
} as const;

export const SendStickerMediaType = {
  WEBP: 'image/webp',
} as const;

export const SendVideoMediaType = {
  MP4: 'video/mp4',
  '3GP': 'video/3gpp',
} as const;

export const SendAudioMediaType = {
  AAC: 'audio/aac',
  MP3: 'audio/mp3',
  AUDIO_MP4: 'audio/mp4',
  MPEG: 'audio/mpeg',
  AMR: 'audio/amr',
  OGG: 'audio/ogg',
  OGG_OPUS: 'audio/ogg; codecs=opus', // This is a type for voice messages
} as const;

export const SendDocumentMediaType = {
  TXT: 'text/plain',
  PDF: 'application/pdf',
  STREAM: 'application/octet-stream',
  BINARY_STREAM: 'binary/octet-stream',
  MS_XLS: 'application/vnd.ms-excel',
  MS_XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  MS_PPT: 'application/vnd.ms-powerpoint',
  MS_PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  MS_DOC: 'application/msword',
  MS_DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  CORELDRAW: 'image/x-coreldraw',
} as const;

export const SendMediaType = {
  ...SendImageMediaType,
  ...SendStickerMediaType,
  ...SendVideoMediaType,
  ...SendAudioMediaType,
  ...SendDocumentMediaType,
} as const;

export const ImageMediaExtensions = {
  [SendImageMediaType.JPEG]: 'jpg',
  [SendImageMediaType.PNG]: 'png',
} as const;

export const StickerMediaExtensions = {
  [SendStickerMediaType.WEBP]: 'webp',
} as const;

export const VideoMediaExtensions = {
  [SendVideoMediaType.MP4]: 'mp4',
  [SendVideoMediaType['3GP']]: '3gp',
} as const;

export const AudioMediaExtensions = {
  [SendAudioMediaType.AAC]: 'aac',
  [SendAudioMediaType.AUDIO_MP4]: 'mp4',
  [SendAudioMediaType.MPEG]: 'mpeg',
  [SendAudioMediaType.AMR]: 'amr',
  [SendAudioMediaType.OGG_OPUS]: 'ogg',
  [SendAudioMediaType.MP3]: 'mp3',
} as const;

export const DocumentMediaExtensions = {
  [SendDocumentMediaType.TXT]: 'txt',
  [SendDocumentMediaType.PDF]: 'pdf',
  [SendDocumentMediaType.MS_PPT]: 'ppt',
  [SendDocumentMediaType.MS_PPTX]: 'pptx',
  [SendDocumentMediaType.MS_DOC]: 'doc',
  [SendDocumentMediaType.MS_DOCX]: 'docx',
  [SendDocumentMediaType.CORELDRAW]: 'cdr',
} as const;

export const MediaExtensions = {
  ...ImageMediaExtensions,
  ...StickerMediaExtensions,
  ...VideoMediaExtensions,
  ...AudioMediaExtensions,
  ...DocumentMediaExtensions,
} as const;

const CHARS_EXCEPT_EMOJIS = /^[\uD83C-\uDBFF\uDC00-\uDFFF]+$/;

export const containsOnlyEmojis = (text: string) => {
  return CHARS_EXCEPT_EMOJIS.test(text);
};

export const INSUFFICIENT_BALANCE_ERROR_KEY = 'Insufficient balance';

export const CHAT_SLIDER_OPEN_STATE_KEY = 'chat_slider_open_state';


export const VIDEO_FOR_HEALTHY_ECOSYSTEM = 'https://www.youtube.com/watch?v=_Q3qfS5PWjQ';

export const VIDEO_FOR_FREQUENCY_CAP_ERROR = {
  video: 'https://youtu.be/mxKEBRioNY4',
  thumbnail: 'https://img.youtube.com/vi/mxKEBRioNY4/hqdefault.jpg',
};

export const READ_MORE_CHARACTER_LIMIT = 750;

export const READ_MORE_LINE_LIMIT = 10;
